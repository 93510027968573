import './flyover.scss'

import SweetScroll from "sweet-scroll"
import Component from "../../shared/component"

const CLASS = "vc-flyover"
const breakpoint = 768

export const ClassName = {
  ROOT: CLASS
}

export const Selector = {
  ROOT: `.${ClassName.ROOT}`,
  ITEM: `.vc-flyover-anchor-link`,
}

// -----------------------------------------------------------------------------
// Class Definition
// -----------------------------------------------------------------------------

export default class Flyover extends Component {
  get items() {
    return Array.from(this.root.querySelectorAll(Selector.ITEM))
  }

  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new Flyover(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    let viewportWidth
    if (this.root) {
      this.attachEventListeners()
    }
  }

  getScrollOffset() {
    this.viewportWidth = window.innerWidth || document.documentElement.clientWidth
    if (this.viewportWidth <= breakpoint) {
      this.scrollOffset = -72
    } else {
      this.scrollOffset = -180
    }
  }

  attachEventListeners() {
    this.items.forEach(item => {
      item.addEventListener(
        "click",
        (this._onClickHandler = event => {
          this.getScrollOffset()
          event.preventDefault()
          const scroller = new SweetScroll({
            easing: "easeInExpo",
            duration: 500,
            offset: this.scrollOffset
          })
          if (scroller) scroller.toElement(document.getElementById(item.dataset.target))
        })
      )
    })
  }

  destroy() {
    if (this.root && this._onClickHandler)
      this.root.removeEventListener("click", this._onClickHandler)
  }
}
