import "./stage.scss"

import SweetScroll from "sweet-scroll"
import Component from "../../shared/component"

const CLASS = "vc-stage"

export const ClassName = {
  ROOT: CLASS
}

export const Selector = {
  ROOT: `.${ClassName.ROOT}`,
  HISTORY_BACK: `.vc-stage-history-back`,
}

// -----------------------------------------------------------------------------
// Class Definition
// -----------------------------------------------------------------------------

export default class Stage extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new Stage(element)
      components.push(element.instance)
    })
    return components
  }

  get nextModule() {
    if (this.root) {
      return this.root.nextElementSibling
    }

    return undefined
  }

  get historyBackButton() {
    if (this.root) {
      return this.root.querySelector(Selector.HISTORY_BACK)
    }

    return undefined
  }

  init() {
    if (this.root) {
      this.root.addEventListener(
        "click",
        (this._onClickHandler = evt => {
          if (evt.target) {
            const scrollDownButton = evt.target.closest(
              ".vc-stage-scrollDownButton"
              )
              if (scrollDownButton) {
              evt.preventDefault();
              const scroller = new SweetScroll({
                easing: "easeInExpo",
                duration: 500,
                offset: -100
              })
              // console.log(this.nextModule)
              if (scroller) scroller.toElement(this.nextModule)
            }
          }
        })
      )
    }
    if (this.historyBackButton) {
      // console.log(window.history);
      this.historyBackButton.addEventListener("click", event => {
        // TODO: length > 5 is only temp fix
        if (window.history.state.index > 0) {
          window.history.back()
        } else {
          // console.log("go to start");
          // window.location.replace('https://www.volocopter.com');
          // window.location.href = 'https://www.volocopter.com';
          window.location.href = '/';
        }
      })
    }
  }

  destroy() {
    if (this.root && this._onClickHandler)
      this.root.removeEventListener("click", this._onClickHandler)
  }
}
