import "./scroll-progress-bar.scss"
import Component from '../../shared/component'

// -----------------------------------------------------------------------------
// Constants
// -----------------------------------------------------------------------------

const CLASS = 'vc-scroll-progress-bar'

export const ClassName = {
  ROOT: CLASS
}

export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

// -----------------------------------------------------------------------------
// Class Definition
// -----------------------------------------------------------------------------

export default class ScrollProgressBar extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new ScrollProgressBar(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    this.progressIndicator = this.root.querySelector(`.vc-scroll-progress-bar__indicator`)

    window.addEventListener("scroll", event => {
      this._handleScrollbar()
    })
  }

  _handleScrollbar() {
    let windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
    let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    let scrolled = (windowScroll / height) * 100;
    this.progressIndicator.style.width = scrolled + "%";
  }
}