import Component, { attachTo } from "@shared/component"

import "./blog-feed.scss"

export const create = attachTo(`.vc-blog-feed`, root => {
  return new BlogFeed(root)
})

export default class BlogFeed extends Component {
  init() {
    this.filterAll = this.root.querySelector(`.vc-blog-feed-main-filter-element-all`)
    this.filterBlog = this.root.querySelector(`.vc-blog-feed-main-filter-element-blog`)
    this.filterPress = this.root.querySelector(`.vc-blog-feed-main-filter-element-press`)
    this.categoryFilter = this.root.querySelector(`.vc-blog-feed-category-filter`)
    const itemsToShow = this.categoryFilter.dataset.amount || 6;
    let activeMainFilter = "all"
    let activeCategoryFilters = []
    this.allCards = Array.from(this.root.querySelectorAll(`.vc-blog-feed-card`))
    this.allPressCards = Array.from(this.root.querySelectorAll(`.vc-blog-feed-card[data-posttype="post"]`))
    this.allBlogCards = Array.from(this.root.querySelectorAll(`.vc-blog-feed-card[data-posttype="blog_entry"]`))

    // this.filterMainElements = this.root.querySelectorAll(`.vc-blog-feed-main-filter-element`)
    this.filterMainElements = Array.from(this.root.querySelectorAll(`.vc-blog-feed-main-filter-element`))
    this.filterCategoryElements = Array.from(this.root.querySelectorAll(`.vc-blog-feed-tag`))
    this.filterCategoryAll = this.root.querySelector(`.vc-blog-feed-tag-all`)

    this.filterMainElements.forEach(element => {
      element.addEventListener('click', event => {
        event.preventDefault()
        event.stopPropagation()
        this.filterMainElements.forEach(element => {
          element.classList.remove('is-active')
        })
        element.classList.add('is-active')
        activeMainFilter = element.getAttribute('data-filter')
        this.newMainFilter(activeMainFilter)
      })
    })

    this.filterCategoryElements.forEach(element => {
      element.addEventListener('click', event => {
        event.preventDefault()
        event.stopPropagation()
        let filterCategory = element.getAttribute('data-filter')
        // console.log(filterCategory)
        if (element.classList.contains('is-active') == false) {
          activeCategoryFilters.push(filterCategory)
          element.classList.add('is-active')
        } else {
          element.classList.remove('is-active')
          activeCategoryFilters = activeCategoryFilters.filter(item => item !== filterCategory)
        }
        // console.log(activeCategoryFilters)
        this.newCategoryFilter(activeCategoryFilters)
        // activeCategoryFilters.push(filterCategory)
        // element.classList.toggle('is-active')
      })
      this.filterCategoryAll.addEventListener('click', event => {
        event.preventDefault()
        event.stopPropagation()
        this.filterCategoryElements.forEach(element => {
          element.classList.remove('is-active')
        })
        activeCategoryFilters = []
        this.newCategoryFilter(activeCategoryFilters)
      })
    })

    this.newMainFilter = function(mainFilter) {
      this.allCards.forEach(element => {
        element.classList.remove('is-active')
        element.classList.remove('is-visible')
        activeCategoryFilters = []
        this.filterCategoryElements.forEach(element => {
          element.classList.remove('is-active')
        })
        this.filterCategoryAll.classList.add('is-active')
        if (mainFilter == 'post') {
          this.categoryFilter.classList.add('is-visible')
        } else {
          this.categoryFilter.classList.remove('is-visible')
        }
        if (mainFilter == 'all' || mainFilter == element.getAttribute('data-posttype')) {
          element.classList.add('is-active')
        } else {
          element.classList.remove('is-active')
        }
      })
      this.showFirstCards()
    }

    this.newCategoryFilter = function(categoryFilters) {
      this.allCards.forEach(element => {
        element.classList.remove('is-visible')
      })
      if (categoryFilters <= 1) {
        this.filterCategoryAll.classList.add('is-active')
        this.allPressCards.forEach(card => {
          card.classList.add('is-active')
        })
      } else {
        this.filterCategoryAll.classList.remove('is-active')
        this.allPressCards.forEach(card => {
          let cardCategoriesString = card.getAttribute('data-category')
          let cardCategories = JSON.parse(cardCategoriesString)
  
          // compares if both arrays contain at least one of the same elements
          const found = categoryFilters.some(r=> cardCategories.indexOf(r) >= 0)
  
          if (found) {
            card.classList.add('is-active')
          } else {
            card.classList.remove('is-active')
          }
        })
      }
      this.showFirstCards()
    }

    this.showFirstCards = function() {
      let activeCards = Array.from(this.root.querySelectorAll(`.vc-blog-feed-card.is-active`))
      activeCards.forEach(function (card, index) {
        if (itemsToShow < 0 || index < itemsToShow) {
          card.classList.add('is-visible')
        } else {
          card.classList.remove('is-visible')
        }
      });
    }

    this.loadMoreCards = function() {
      let activeCardsNotVisible = Array.from(this.root.querySelectorAll(`.vc-blog-feed-card.is-active:not(.is-visible)`))
      activeCardsNotVisible.forEach(function (card, index) {
        if (index <= 5) {
          card.classList.add('is-visible')
        }
      });
      if (activeCardsNotVisible.length <= 6) {
        this.loadMoreButton.classList.add("hidden")
      } else {
        this.loadMoreButton.classList.remove("hidden")
      }
    }

    // console.log(this.filterAll);
    // console.log(this.filterBlog);
    // console.log(this.filterPress);
    // console.log(this.filterMainElements);

    // Array.from(document.querySelectorAll(elementName)).forEach(element => {
    //   element
    // })

    this.loadMoreButton = this.root.querySelector(
      `.vc-blog-feed-loadMoreButton a`
    )
    if (this.loadMoreButton) {
      this.loadMoreButton.addEventListener(
        "click",
        (this.loadMoreButtonClickHandler = event => {
          event.preventDefault()
          this.loadMoreCards()
        })
      )
    }

    this.showFirstCards()
    // console.log(window.location.pathname)

    // const url_string = window.location.href
    // var url = new URL(url_string);
    // var filterParameter = url.searchParams.get("filter");
    // console.log(filterParameter);
    this.checkURLAndClick()
  }

  loadMore() {
    const cardsToReveal = 6
    const hiddenCards = Array.from(
      this.root.querySelectorAll(`.vc-blog-feed-card.hidden`)
    )
    const nextSixCards = hiddenCards.slice(0, cardsToReveal);

    nextSixCards.forEach(card => {
      card.classList.remove("hidden")
    })
  }

  destroy() {
    if (this.loadMoreButton && this.loadMoreButtonClickHandler) {
      this.loadMoreButton.removeEventListener(
        "click",
        this.loadMoreButtonClickHandler
      )
    }
  }

  checkURLAndClick() {
    const url_string = window.location.href
    var url = new URL(url_string);
    var filterParameter = url.searchParams.get("filter");
    if (filterParameter == 'blog') {
      let blogButton = this.root.querySelector('.vc-blog-feed-main-filter-element-blog')
      if (blogButton) {
        blogButton.click()
      }
    } else if (filterParameter == 'press') {
      let pressButton = this.root.querySelector('.vc-blog-feed-main-filter-element-press')
      if (pressButton) {
        pressButton.click()
      }
    }
  }
}
