import './share-menu.scss'
import Component from "../../shared/component"

const CLASS = "vc-share-menu"

export const ClassName = {
  ROOT: CLASS
}

export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

// -----------------------------------------------------------------------------
// Class Definition
// -----------------------------------------------------------------------------

export default class ShareMenu extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new ShareMenu(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    // console.log(this.root);
    let shareLink = this.root.querySelector('.vc-newsroom-share-link')
    let copyMessage = this.root.querySelector('.vc-newsroom-share-linkmessage')
    if (shareLink) {
      shareLink.addEventListener('click', event => {
        let currentURL = window.location.href
        event.preventDefault()
        event.stopPropagation()
        // elementB.classList.toggle('modifier')
        copyTextToClipboard(currentURL);
        // copyMessage.classList.add('is-visible')
      })
    }

    function copyTextToClipboard(text) {
      var textArea = document.createElement("textarea");

      //
      // *** This styling is an extra step which is likely not required. ***
      //
      // Why is it here? To ensure:
      // 1. the element is able to have focus and selection.
      // 2. if the element was to flash render it has minimal visual impact.
      // 3. less flakyness with selection and copying which **might** occur if
      //    the textarea element is not visible.
      //
      // The likelihood is the element won't even render, not even a
      // flash, so some of these are just precautions. However in
      // Internet Explorer the element is visible whilst the popup
      // box asking the user for permission for the web page to
      // copy to the clipboard.
      //

      // Place in the top-left corner of screen regardless of scroll position.
      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;

      // Ensure it has a small width and height. Setting to 1px / 1em
      // doesn't work as this gives a negative w/h on some browsers.
      textArea.style.width = '2em';
      textArea.style.height = '2em';

      // We don't need padding, reducing the size if it does flash render.
      textArea.style.padding = 0;

      // Clean up any borders.
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';

      // Avoid flash of the white box if rendered for any reason.
      textArea.style.background = 'transparent';


      textArea.value = text;

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
        if (msg = 'successful') {
          copyMessage.classList.add('is-visible')
        }
      } catch (err) {
        console.log('Oops, unable to copy');
      }

      document.body.removeChild(textArea);
    }

    // if (this.root) {
    //   this.root.addEventListener(
    //     "click",
    //     (this._onClickHandler = evt => {
    //       if (evt.target) {
    //         const scrollDownButton = evt.target.closest(
    //           ".vc-stage-scrollDownButton"
    //           )
    //           if (scrollDownButton) {
    //           evt.preventDefault();
    //           const scroller = new SweetScroll({
    //             easing: "easeInExpo",
    //             duration: 500,
    //             offset: -100
    //           })
    //           // console.log(this.nextModule)
    //           if (scroller) scroller.toElement(this.nextModule)
    //         }
    //       }
    //     })
    //   )
    // }
  }
}
