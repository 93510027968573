import "./footer-newsletter.scss"
import axios from "axios"
import Component, { attachTo } from "@shared/component"

export const create = attachTo(`.vc-footer-newsletter`, root => {
  return new NewsletterSignup(root)
})

const defaultProperties = {
  action: "submit_newsletter_signup_form",
  token: window.ajaxnonce,
  url: window.ajaxurl
}

export default class NewsletterSignup extends Component {
  init() {
    this.form = this.root.querySelector('[name="form-newsletter"]')
    this.regexValidateMail = function(email) {
      const re = /^\S+@\S+\.\S+$/;
      return re.test(email);
    }
    this.enableDisable = function(field) {
      if (this.regexValidateMail(field.value) && this.validate()) {
        this.submitButtonDesktop.removeAttribute("disabled")
        this.submitButtonMobile.removeAttribute("disabled")
      } else {
        this.submitButtonDesktop.disabled = true;
        this.submitButtonMobile.disabled = true;
      }
    }

    if (this.form) {
      this.submitButtonDesktop = this.root.querySelector(
        `.vc-footer-newsletter-submit-desktop`
      )
      this.submitButtonMobile = this.root.querySelector(
        `.vc-footer-newsletter-submit-mobile`
      )
      const fields = Array.from(this.root.querySelectorAll("input, textarea"));
      fields.forEach(
        field => {
          field.addEventListener("focus", event => {
            event.currentTarget.parentNode.classList.remove("is-invalid")
          })
          if (field.classList.contains('vc-footer-newsletter-input-mail')) {
            field.addEventListener("input", event => {
              this.enableDisable(field)
            })
          }
        }
      )
      this.form.querySelector(`[name="consent"]`).addEventListener("click", () => this.enableDisable(this.form.querySelector(".vc-footer-newsletter-input-mail")))

      if (this.submitButtonDesktop) {
        this.submitButtonDesktop.addEventListener("click", event => {
          event.preventDefault()
          if (this.validate()) {
            this.submit()
          }
        })
      }
      if (this.submitButtonMobile) {
        this.submitButtonMobile.addEventListener("click", event => {
          event.preventDefault()
          if (this.validate()) {
            this.submit()
          }
        })
      }
    }
  }

  validate() {
    const errors = []

    const email = this.form.querySelector(`[name="email"]`)
    if (email && email.value === "") {
      errors.push({ field: "email", empty: true })
    }

    const consent = this.form.querySelector(`[name="consent"]`)
    if (consent && consent.checked === false) {
      errors.push({ field: "consent", empty: true })
    }

    return errors.length === 0
  }

  showSuccessMessage() {
    const fields = this.root.querySelector(`.vc-footer-newsletter-loading-wrapper`)
    if (fields) fields.classList.add("hidden")
    const message = this.root.querySelector(`.vc-footer-newsletter-message-wrapper`)
    if (message) message.classList.remove("hidden")
  }

  showLoading() {
    const fields = this.root.querySelector(`.vc-footer-newsletter-form-wrapper`)
    if (fields) fields.classList.add("hidden")
    const message = this.root.querySelector(`.vc-footer-newsletter-loading-wrapper`)
    if (message) message.classList.remove("hidden")
  }

  showContactExistsMessage() {
    const fields = this.root.querySelector(`.vc-footer-newsletter-loading-wrapper`)
    if (fields) fields.classList.add("hidden")
    const message = this.root.querySelector(`.vc-footer-newsletter-user-exists-error-message-wrapper`)
    if (message) message.classList.remove("hidden")
  }

  showErrorMessage() {
    const fields = this.root.querySelector(`.vc-footer-newsletter-loading-wrapper`)
    if (fields) fields.classList.add("hidden")
    const message = this.root.querySelector(`.vc-footer-newsletter-error-message-wrapper`)
    if (message) message.classList.remove("hidden")
  }


  submit() {
    const data = new FormData(this.form)
    this.showLoading();
    axios.post(this.form.getAttribute("action"), data).then(response => {
      if (response.data.success === true) {
        console.log(response)
        this.showSuccessMessage()
      } else {
        if (response.data.data.message.includes("Recipient with email") && response.data.data.message.includes("already exists")) {
          this.showContactExistsMessage()
        } else {
          this.showErrorMessage()
        }
        console.warn(response)
      }
    }).catch(() => this.showErrorMessage())
  }
}
