import Component, { attachTo } from "@shared/component"

import "./card-row.scss"

export const create = attachTo(`.vc-card-row`, root => {
  return new CardRow(root)
})

export default class CardRow extends Component {
  init() {
    this.loadMoreButton = this.root.querySelector(
      `.vc-card-row-loadMoreButton a`
    )
    if (this.loadMoreButton) {
      this.loadMoreButton.addEventListener(
        "click",
        (this.loadMoreButtonClickHandler = event => {
          event.preventDefault()
          this.loadMore()
        })
      )
    }
  }

  loadMore() {
    const cardsToReveal = 3
    const hiddenCards = Array.from(
      this.root.querySelectorAll(`.vc-card-row-card.hidden`)
    )
    const nextSixCards = hiddenCards.slice(0, cardsToReveal);

    nextSixCards.forEach(card => {
      card.classList.remove("hidden")
    })

    if (this.loadMoreButton && hiddenCards.length < cardsToReveal) {
      this.loadMoreButton.classList.add("hidden")
    }
  }

  destroy() {
    if (this.loadMoreButton && this.loadMoreButtonClickHandler) {
      this.loadMoreButton.removeEventListener(
        "click",
        this.loadMoreButtonClickHandler
      )
    }
  }
}
