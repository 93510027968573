import "./jobs-dev-blog.scss"
// import "./../jobs-filter/jobs-filter.scss"
import "./../jobs-list/jobs-list.scss"
import Component from "../../shared/component"
// import "mapbox-gl/dist/mapbox-gl.css"

// import axios from "axios"
// import SweetScroll from "sweet-scroll"

// -----------------------------------------------------------------------------
// Constants
// -----------------------------------------------------------------------------

const CLASS = "vc-jobs"

export const ClassName = {
  ROOT: CLASS
}

export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

// -----------------------------------------------------------------------------
// Class Definition
// -----------------------------------------------------------------------------

export default class JobsDevBlog extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new JobsDevBlog(element)
      components.push(element.instance)
    })
    return components
  }

  init() {

  }
}
