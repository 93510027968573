import barba from "@barba/core"
import anime from "animejs"
import LazyLoad from "vanilla-lazyload"

import { clearAllBodyScrollLocks } from "body-scroll-lock"
import scheduler, { loop } from "@downpourdigital/scheduler"

import "@assets"
import "@shared/global.scss"

import "@components/button"
import "@components/image"
import "@components/button-animation"
import "@components/language-switch"
import "@components/formatted-text"
import "@components/input"
// import "@components/scroll-progress-bar"
// import "@components/slide-indicator"
// import "@components/job-detail-card"
// import "@modules/icon-text-row"
// import "@modules/job-detail"

import { destroyAll } from "@shared/component"
import { reveal } from "@shared/animations"
import { create as createVideo } from "@components/video"
import { create as createHeader } from "@modules/header"
import { create as createContactForm } from "@modules/contact-form"
// import { create as createLoginForm } from "@modules/login-form"
import { create as createAnimation } from "@modules/animation"
import { create as createJobListing } from "@modules/job-listing"
import { create as createNewsroomFeed } from "@modules/newsroom-feed"
import { create as createBlogFeed } from "@modules/blog-feed"
import { create as createNewsletterSignup } from "@modules/newsletter-signup"
// import { create as createInvestorNewsletterSignup } from "@modules/investor-newsletter-signup"
import { create as createFooterNewsletter } from "@components/footer-newsletter"
import { create as createCardRow } from "@modules/card-row"

import ShareMenu from "@components/share-menu"
import Flyover from "@components/flyover"
// import VideoPlayerComponent from "@components/videoplayer-component"
// import Slider from "@modules/slider"
// import SlideshowText from "@modules/slideshow-text"
// import SolutionsSlider from "@modules/solutions-slider"
// import ESGSlider from "@modules/esg-slider"
// import ImageGallery from "@modules/image-gallery"
// import Heroslider from "@modules/heroslider"
// import MatomoOptoutButton from "@components/matomo-optout-button"
// import Accordion from "@modules/accordion"
// import Cardslider from "@modules/cardslider"
// import ManagementBoard from "@modules/management-board"
import Stage from "@modules/stage"
import Anchor from "@modules/anchor"
import Footer from "@modules/footer"
import ScrollProgressBar from "@components/scroll-progress-bar"
import Search from "@components/search"
// import VideoPlayer from "@modules/videoplayer"
// import Whitepage from "@modules/whitepage"
// import TextAccordion from "@modules/text-accordion"
// import IllustrationCards from "@modules/illustration-cards"
// import StoriesSlider from "@modules/stories-slider"
// import Jobs from "@modules/jobs"
import JobsDevBlog from "@modules/jobs-dev-blog"
// import JobsFilter from "@modules/jobs-filter"
// import JobsMap from "@modules/jobs-map"

class Volocopter {
  constructor() {
    this.root = document.querySelector("body")
    this.init(this.root)
    this.run()
  }

  // TODO: add to instance
  init(scope) {
    this.fixLinkBehaviour(scope)

    this.instances = []
    this.instances = this.instances.concat(createContactForm(scope))
    // this.instances = this.instances.concat(createLoginForm(scope))
    this.instances = this.instances.concat(createHeader(scope))
    this.instances = this.instances.concat(createAnimation(scope))
    // this.instances = this.instances.concat(createVideo(scope))
    this.instances = this.instances.concat(createJobListing(scope))
    this.instances = this.instances.concat(createNewsletterSignup(scope))
    // this.instances = this.instances.concat(createInvestorNewsletterSignup(scope))
    this.instances = this.instances.concat(createFooterNewsletter(scope))
    this.instances = this.instances.concat(createNewsroomFeed(scope))
    this.instances = this.instances.concat(createBlogFeed(scope))
    this.instances = this.instances.concat(createCardRow(scope))

    // TODO: adjust to factory method
    // this.instances = this.instances.concat(ShareMenu.upgradeAll())
    // this.instances = this.instances.concat(Slider.upgradeAll())
    // this.instances = this.instances.concat(SlideshowText.upgradeAll())
    // this.instances = this.instances.concat(SolutionsSlider.upgradeAll())
    // this.instances = this.instances.concat(ESGSlider.upgradeAll())
    // this.instances = this.instances.concat(ImageGallery.upgradeAll())
    // this.instances = this.instances.concat(Accordion.upgradeAll())
    // this.instances = this.instances.concat(Heroslider.upgradeAll())
    // this.instances = this.instances.concat(MatomoOptoutButton.upgradeAll())
    // this.instances = this.instances.concat(Cardslider.upgradeAll())
    // this.instances = this.instances.concat(ManagementBoard.upgradeAll())
    this.instances = this.instances.concat(Footer.upgradeAll())
    this.instances = this.instances.concat(Anchor.upgradeAll())
    this.instances = this.instances.concat(Stage.upgradeAll())
    this.instances = this.instances.concat(Flyover.upgradeAll())
    this.instances = this.instances.concat(ScrollProgressBar.upgradeAll())
    // this.instances = this.instances.concat(Search.upgradeAll())
    // this.instances = this.instances.concat(VideoPlayerComponent.upgradeAll())
    // this.instances = this.instances.concat(VideoPlayer.upgradeAll())
    // this.instances = this.instances.concat(Whitepage.upgradeAll())
    // this.instances = this.instances.concat(TextAccordion.upgradeAll())
    // this.instances = this.instances.concat(IllustrationCards.upgradeAll())
    // this.instances = this.instances.concat(StoriesSlider.upgradeAll())
    // this.instances = this.instances.concat(Jobs.upgradeAll())
    this.instances = this.instances.concat(JobsDevBlog.upgradeAll())
    // this.instances = this.instances.concat(JobsFilter.upgradeAll())
    // this.instances = this.instances.concat(JobsMap.upgradeAll())

    this.initLazyLoading()
  }

  initLazyLoading() {
    if (!this.lazyLoad) {
      this.lazyLoad = new LazyLoad({
        elements_selector: "[data-src]"
      })
    }
    this.lazyLoad.update()
  }

  animateIn(scope) {
    const tl = anime.timeline({
      easing: "easeOutExpo"
    })
    tl.add(
      {
        targets: scope.querySelector(".vc-header-bar"),
        translateY: ["-100%", "0%"],
        duration: 1000
      },
      250
    )
    const stageCaption = scope.querySelector(".vc-stage-caption")
    if (stageCaption) {
      tl.add(
        {
          targets: stageCaption,
          opacity: [0, 1],
          translateY: ["25%", "0%"],
          duration: 1000
        },
        250
      )
    }
    const stageMedia = scope.querySelector(".vc-stage-media")
    if (stageMedia) {
      tl.add(
        {
          targets: stageMedia,
          scale: [1.1, 1],
          duration: 1000
        },
        250
      )
    }
    reveal(scope)
  }

  destroy() {
    destroyAll(this.instances)
    this.instances = []
  }

  fixLinkBehaviour(scope) {
    const links = scope.querySelectorAll("a[href]")
    for (var i = 0; i < links.length; i++) {
      links[i].addEventListener("click", event => {
        if (
          event.currentTarget.href.replace(/\/$/, "") ===
          window.location.href.replace(/\/$/, "")
        ) {
          event.preventDefault()
          event.stopPropagation()
        }
      })
    }
  }

  trackPageView() {
    if (window._paq) {
      window._paq.push(["setCustomUrl", document.location.href])
      window._paq.push(["setDocumentTitle", document.title])
      window._paq.push(["trackPageView"])
    }
  }

  run() {
    // TODO: clean
    const vc = this
    barba.init({
      prefetchIgnore: ['/newsletter/unsubscribe', '/suppliers'],
      prevent: ({ el }) => el.hasAttribute("download"),
      transitions: [
        {
          name: "default",
          once(c) {
            vc.animateIn(vc.root)
            scheduler.start()
          },
          leave(c) {
            const transition = anime({
              targets: c.current.container,
              opacity: [1, 0],
              easing: "linear",
              duration: 350
            })
            return transition.finished
          },
          enter(c) {
            // if (history.scrollRestoration) {
            //   history.scrollRestoration = 'manual';
            // }
            
            // if (data.trigger === 'back') {
            //   console.log('back');
            // } else {
            //   console.log('not back');
            // }



            // document.documentElement.scrollTop = 0
            const transition = anime({
              targets: c.next.container,
              opacity: [0, 1],
              easing: "linear",
              duration: 350
            })
            return transition.finished
          }
        }
      ]
    })
    history.scrollRestoration = "manual";
    let scrollPosY
    let scrolledBackOnce = false
    barba.hooks.afterEnter(data => {
      this.trackPageView()
    })
    barba.hooks.beforeEnter(data => {
      clearAllBodyScrollLocks()
      data.current.container.style.display = `none`
      this.fixLinkBehaviour(data.next.container)
      this.init(data.next.container)
      this.animateIn(data.next.container)
      scheduler.start()
    })
    barba.hooks.enter((data) => {
      if(data.trigger !== "back") {
        scrollPosY = barba.history.current.scroll.y;
      }
    })
    barba.hooks.leave(data => {
      this.destroy()
      scheduler.stop()
    })
    barba.hooks.after(data => {
      if(data.trigger === "back" && scrolledBackOnce === false) {
        window.scrollTo(0, scrollPosY)
        scrolledBackOnce = true
      } else {
        window.scrollTo(0,0);
        scrolledBackOnce = false
      }
    })
  }
}

window.vc = new Volocopter()
