import "./newsletter-signup.scss"
import axios from "axios"
import Component, { attachTo } from "@shared/component"

export const create = attachTo(`.vc-newsletter-signup`, root => {
  return new NewsletterSignup(root)
})

const defaultProperties = {
  action: "submit_newsletter_signup_form",
  token: window.ajaxnonce,
  url: window.ajaxurl
}

export default class NewsletterSignup extends Component {
  init() {
    this.form = this.root.querySelector("form")
    if (this.form) {
      Array.from(this.root.querySelectorAll("input, textarea")).forEach(
        field => {
          field.addEventListener("focus", event => {
            event.currentTarget.parentNode.classList.remove("is-invalid")
          })
        }
      )
      this.submitButton = this.root.querySelector(
        `.vc-footer-newsletter-submit`
      )
      if (this.submitButton) {
        this.submitButton.addEventListener("click", event => {
          event.preventDefault()
          if (this.validate()) {
            this.submit()
          }
        })
      }
    }
  }

  validate() {
    const errors = []

    const email = this.form.querySelector(`[name="email"]`)
    if (email && email.value === "") {
      email.parentNode.classList.add("is-invalid")
      errors.push({ field: "email", empty: true })
    }

    const consent = this.form.querySelector(`[name="consent"]`)
    if (consent && consent.checked === false) {
      errors.push({ field: "consent", empty: true })
    }

    return errors.length === 0
  }

  showSuccessMessage() {
    const fields = this.root.querySelector(`.vc-newsletter-signup-form`)
    if (fields) fields.classList.add("hidden")
    const message = this.root.querySelector(`.vc-newsletter-signup-message`)
    if (message) message.classList.remove("hidden")
  }

  submit() {
    const data = new FormData(this.form)
    axios.post(this.form.getAttribute("action"), data).then(response => {
      if (response.data.success === true) {
        console.log(response)
        this.showSuccessMessage()
      } else {
        console.warn(response)
      }
    })
  }
}
