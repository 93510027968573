import "./footer.scss"

import SweetScroll from "sweet-scroll"
import Component from "../../shared/component"

const CLASS = "vc-footer"

export const ClassName = {
  ROOT: CLASS
}

export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

// -----------------------------------------------------------------------------
// Class Definition
// -----------------------------------------------------------------------------

export default class Footer extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new Footer(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    if (this.root) {
      this.root.addEventListener(
        "click",
        (this._onClickHandler = evt => {
          if (evt.target) {
            const scrollToTopButton = evt.target.closest(
              ".vc-footer-scrollTopButton"
            )
            if (scrollToTopButton) {
              const scroller = new SweetScroll({
                easing: "easeInExpo",
                duration: 500
              })

              if (scroller) scroller.toTop(0)
            }
          }
        })
      )

      const marqueeContainerElement = this.root.querySelector(
        ".vc-footer-marqueeContainer"
      )
      const marqueeElement = this.root.querySelector(".vc-footer-marquee")
      const marqueeParagraphElement = this.root.querySelectorAll(
        ".vc-footer-marqueeParagraph"
      )
      if (marqueeParagraphElement) {
        const paragraphWidth = marqueeParagraphElement[0].getBoundingClientRect()
          .width
        marqueeElement.style.width = `${
          paragraphWidth * (marqueeParagraphElement.length + 0)
        }px`
      }
    }
  }

  destroy() {
    if (this.root && this._onClickHandler)
      this.root.removeEventListener("click", this._onClickHandler)
  }
}
