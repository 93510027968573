import Component, { attachTo } from "@shared/component"

import "./job-listing.scss"

export const create = attachTo(`.vc-jobListing`, root => {
  return new JobListing(root)
})

export default class JobListing extends Component {
  init() {
    if (window.widget) {
      window.widget(
        {
          company_code: "VolocopterGmbH",
          bg_color_widget: "#ffffff",
          bg_color_headers: "#969696",
          bg_color_links: "#55dd88",
          txt_color_headers: "#292929",
          txt_color_job: "#3d3d3d",
          bg_color_even_row: "#e0e0e0",
          bg_color_odd_row: "#f7f7f7",
          auto_width: "auto",
          auto_height: "auto",
          number: "on",
          jobs_number: "",
          job_title: "true",
          location: "true",
          type_of_employment: "true",
          dateFormat: "us",
          remove_headers: "true",
          filter_departments: `${this.root.dataset.department}`,
          departments_field_id: "5db8369f6cb73c26ed66cd7c",
          jobAdType: "PUBLIC",
          trid: "",
          api_url: "https://www.smartrecruiters.com"
        },
        this.root.getAttribute("id")
      )
    }
  }

  destroy() {
    // TODO
  }
}
