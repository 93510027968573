import lottie from "lottie-web"
import Component, { attachTo } from "@shared/component"

import "./animation.scss"

const CLASS = "vc-animation"

export const ClassName = {
  ROOT: CLASS
}

export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

const defaultProperties = {}

// import "@assets/animations/iq-mobile.json"
// import "@assets/animations/ecosystem-desktop.json"
// import "@assets/animations/ecosystem-mobile.json"
// import "@assets/animations/iq-desktop.json"

export const create = attachTo(Selector.ROOT, root => {
  return new Animation(root, defaultProperties)
})

export default class Animation extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new Animation(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    this.animations = []
    const animations = Array.from(
      this.root.querySelectorAll("[data-animation]")
    )
    animations.forEach(animationEl => {
      this.animations.push(
        lottie.loadAnimation({
          container: animationEl,
          renderer: "svg",
          loop: false,
          autoplay: false,
          path: animationEl.dataset.animation
        })
      )
    })
    this.createIntersectionObserver()
  }

  createIntersectionObserver() {
    this.observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (!entry.isIntersecting) {
            this.play()
          }
        })
      },
      {
        rootMargin: `0px 0px 0px 0px`
      }
    )
    this.observer.observe(this.root)
  }

  destroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
    if (this.animations) {
      this.animations.forEach(animation => {
        animation.destroy()
      })
    }
  }

  play() {
    if (this.animations) {
      this.animations.forEach(animation => {
        animation.play()
      })
    }
  }
}
