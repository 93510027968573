import animejs from "animejs"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock"

import Component, { attachTo } from "@shared/component"
import { normalize, clamp } from "@shared/math"

import "./header.scss"
import anime from "animejs"

const defaultProperties = {
  name: ""
}

export const create = attachTo(`.vc-header`, root => {
  return new Header(root, defaultProperties)
})

export default class Header extends Component {
  init() {
    this.animating = false
    this.open = false
    this.root.addEventListener(
      "click",
      (this._onClickHandler = evt => this._onClick(evt))
    )
    this.headerBar = this.root.querySelector(".vc-header-bar")
    this.theme = this.headerBar.getAttribute("data-theme")

    const stage = this.root
      .closest('[data-barba="container"]')
      .querySelector(".vc-stage")
      // console.log(stage);
    const endmarker = this.root.closest('[data-barba="container"]').querySelector('.vc-stage-endmarker')
    // console.log(endmarker);
    if (stage && endmarker) this.attachIntersectionObserver(endmarker)
  }

  _onClick(evt) {
    if (evt.target) {
      const navToggleElement = evt.target.closest(".vc-header-navtoggle")
      if (navToggleElement) {
        if (!this.animating) {
          this.animating = true
          if (!this.open) {
            this.showMenu(navToggleElement.dataset.direction).then(() => {
              this.animating = false
            })
          } else {
            this.hideMenu().then(() => {
              this.animating = false
            })
          }
        }
      }
    }
  }

  attachIntersectionObserver(stage) {
    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.headerBar.classList.remove("is-sticky")
          } else {
            this.headerBar.classList.add("is-sticky")
          }
        })
      },
      {
        rootMargin: "-200px 0px 0px 0px",
        threshold: 0
      }
    )
    this.observer.observe(stage)
  }

  detachIntersectionObserver() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  destroy() {
    if (this.root && this._onClickHandler) {
      this.root.removeEventListener("click", this._onClickHandler)
    }
  }

  hideMenu() {
    this.open = false
    this.root.classList.remove("is-open")

    const bar = this.root.querySelector(".vc-header-bar")
    const menu = this.root.querySelector(".vc-header-menu")

    const open = anime.timeline({
      easing: "easeInOutQuart"
    })
    open.add(
      {
        targets: bar,
        duration: 500,
        translateY: ["-100%", "0%"]
      },
      0
    )
    open.add(
      {
        targets: menu,
        duration: 1000,
        complete: () => {
          menu.classList.add("hidden")
          clearAllBodyScrollLocks()
        },
        translateY:
          this.direction === "top-bottom" ? ["0%", "-100%"] : ["0%", "100%"]
      },
      0
    )
    open.add(
      {
        targets: this.root.querySelectorAll(`.vc-header-navMenuItem-link`),
        translateY: ["0%", "100%"],
        skew: ["0deg", "-5deg"]
      },
      0
    )
    open.add(
      {
        targets: this.root.querySelector(`.vc-header-contact`),
        opacity: [1, 0]
      },
      0
    )
    return open.finished
  }

  showMenu(direction) {
    this.open = true
    this.root.classList.add("is-open")
    this.direction = direction

    const bar = this.root.querySelector(".vc-header-bar")
    const menu = this.root.querySelector(".vc-header-menu")

    const open = anime.timeline({
      easing: "easeInOutQuart"
    })
    open.add(
      {
        targets: bar,
        duration: 500,
        translateY: ["0%", "-100%"]
      },
      300
    )
    open.add(
      {
        targets: menu,
        duration: 1000,
        begin: () => {
          menu.classList.remove("hidden")
          disableBodyScroll(menu)
        },
        translateY:
          direction === "top-bottom" ? ["-100%", "0%"] : ["100%", "0%"]
      },
      0
    )
    open.add(
      {
        targets: this.root.querySelectorAll(`.vc-header-navMenuItem-link`),
        delay: anime.stagger(50),
        translateY: ["100%", "0%"],
        skew: ["-5deg", "0deg"]
      },
      250
    )
    open.add(
      {
        targets: this.root.querySelector(`.vc-header-contact`),
        opacity: [0, 1]
      },
      250
    )
    return open.finished
  }
}
