import videojs from "video.js"

// import "video.js/src/css/video-js.scss"
import "./video.scss"

import Component, { attachTo } from "@shared/component"

const CLASS = "vc-video"

export const ClassName = {
  ROOT: CLASS
}

export const Selector = {
  ROOT: `.${ClassName.ROOT}`,
  VIDEO: `video`
}

export const States = {
  READY: `is-ready`,
  PLAYING: `is-playing`,
  PAUSED: `is-paused`,
  MUTED: `is-muted`,
  FINISHED: `is-finished`
}

const defaultProperties = {}

export const create = attachTo(Selector.ROOT, root => {
  return new Video(root, defaultProperties)
})

export default class Video extends Component {
  get isPaused() {
    return this.vjs ? this.vjs.paused() : true
  }

  get videoElement() {
    return this.root.querySelector(Selector.VIDEO)
  }

  get options() {
    return {
      autoplay: true,
      controls: false,
      loop: true,
      muted: true,
      loadingSpinner: false,
      html5: {
        vhs: {
          withCredentials: true
        }
      }
    }
  }

  init() {
    if (this.videoElement) {
      this.vjs = videojs(
        this.root.querySelector(Selector.VIDEO),
        this.options,
        () => {
          window.setTimeout(() => {
            this.play()
          }, 2000)
          this.root.classList.add(States.READY)
          this._registerIntersectionObserver()
        }
      )
    }
  }

  _registerIntersectionObserver() {
    this.observer = this._createIntersectionObserver()
    if (this.observer) this.observer.observe(this.root)
  }

  _createIntersectionObserver() {
    return new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          /*if (!entry.isIntersecting) {
            this.pause()
          } else {
            if (!this.root.classList.contains(States.PLAYING)) {
              this.play()
            }
          }*/
        })
      },
      {
        rootMargin: `0px 0px 0px 0px`,
        threshold: [0, 0.25, 0.5, 0.75, 1]
      }
    )
  }

  destroy() {
    if (this.vjs) this.vjs.dispose()
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  pause() {
    if (this.vjs) this.vjs.pause()
  }

  play() {
    if (this.vjs) this.vjs.play()
  }
}
