import anime from "animejs"

import "./animations.scss"

export const triggerAnimationInViewport = (root, animation) => {
  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !root.classList.contains("has-triggered")) {
          root.classList.add("has-triggered")
          animation.play()
        }
      })
    },
    {
      rootMargin: "0px 0px 0px 0px",
      threshold: [0, 0.5]
    }
  )
  observer.observe(root)
}

export const reveal = scope => {
  const targets = Array.from(
    scope.querySelectorAll(`[data-animation="reveal"]`)
  )

  if (targets.length > 0) {
    const animations = []

    targets.forEach(target => {
      Array.from(target.querySelectorAll("span")).forEach(r => {
        r.classList.add("mask")
        r.innerHTML = `<span class="inner">${r.innerHTML}</span>`
      })
      const tl = anime.timeline({
        autoplay: false,
        loop: false,
        easing: "easeInOutQuart"
      })
      tl.add({
        targets: target.querySelectorAll(`span.inner`),
        skew: ["-5deg", "0deg"],
        translateY: ["100%", "0%"],
        duration: 1000,
        delay: anime.stagger(50)
      })
      animations.push(triggerAnimationInViewport(target, tl))
    })
  }
  return animations
}
