import axios from "axios"
import Component, { attachTo } from "@shared/component"

const defaultProperties = {
  action: "submit_contact_form",
  token: window.ajaxnonce,
  url: window.ajaxurl
}

export const create = attachTo(`.vc-contact-form`, root => {
  return new ContactForm(root, defaultProperties)
})

export default class ContactForm extends Component {
  init() {
    this.form = this.root.querySelector("form")
    if (this.form) {
      Array.from(this.root.querySelectorAll("input, textarea")).forEach(
        field => {
          field.addEventListener("focus", event => {
            event.currentTarget.parentNode.classList.remove("is-invalid")
          })
        }
      )
      this.submitButton = this.root.querySelector(`.vc-contact-form-submit a`)
      if (this.submitButton) {
        this.submitButton.addEventListener("click", event => {
          event.preventDefault()
          if (this.validate()) {
            this.showSuccessMessage()
            this.submit()
          }
        })
      }
    }
  }

  // TODO: clean up
  validate() {
    const errors = []

    const name = this.form.querySelector(`[name="name"]`)
    if (name && name.value === "") {
      name.parentNode.classList.add("is-invalid")
      errors.push({ field: "name", empty: true })
    }

    const surname = this.form.querySelector(`[name="surname"]`)
    if (surname && surname.value === "") {
      surname.parentNode.classList.add("is-invalid")
      errors.push({ field: "surname", empty: true })
    }

    /*const company = this.form.querySelector(`[name="company"]`)
    if (company && company.value === "") {
      company.parentNode.classList.add("is-invalid")
      errors.push({ field: "company", empty: true })
    }*/

    const email = this.form.querySelector(`[name="email"]`)
    if (email && email.value === "") {
      email.parentNode.classList.add("is-invalid")
      errors.push({ field: "email", empty: true })
    }

    const message = this.form.querySelector(`[name="message"]`)
    if (message && message.value === "") {
      message.parentNode.classList.add("is-invalid")
      errors.push({ field: "message", empty: true })
    }

    const consent = this.form.querySelector(`[name="consent"]`)
    if (consent && consent.checked === false) {
      errors.push({ field: "consent", empty: true })
    }

    return errors.length === 0
  }

  showSuccessMessage() {
    const fields = this.root.querySelector(`.vc-contact-form-fields`)
    if (fields) fields.classList.add("hidden")
    const message = this.root.querySelector(`.vc-contact-form-message`)
    if (message) message.classList.remove("hidden")
  }

  submit() {
    const data = new FormData(this.form)
    data.append("action", this.property("action"))
    data.append("token", this.property("token"))

    axios.post(this.property("url"), data).then(response => {
      if (response.success) {
        // console.log(response)
      } else {
        console.warn(response)
      }
    })
  }
}
